@import 'react-quill/dist/quill.snow.css';
@import 'react-quill/dist/quill.bubble.css';
@import 'quill-image-uploader/dist/quill.imageUploader.min.css';

.quill .ql-editor {
  font-size: 16px;
  word-break: break-word;

  > * {
    cursor: inherit;
  }

  .image-uploading {
    &:before {
      top: initial;
      bottom: 4px;
      left: initial;
      right: 4px;
      width: 20px;
      height: 20px;
    }

    img {
      max-width: 100% !important;
      filter: none;
      opacity: 1;
    }
  }
}

.quill.h-small > .ql-container > .ql-editor {
  height: 95px;
}

.quill.h-middle > .ql-container > .ql-editor {
  height: 145px;
}

.quill.min-h-small > .ql-container > .ql-editor {
  min-height: 95px;
}

.quill.max-h-small > .ql-container > .ql-editor {
  max-height: 120px;
}

.quill.min-h > .ql-container > .ql-editor {
  min-height: 250px;
}

.quill:not(.min-h-small):not(.min-h):not(.h-small) > .ql-container > .ql-editor.ql-blank::before {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.quill-container-h-full {
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;

  .ql-toolbar {
    flex: none;
  }

  .ql-container {
    flex: 1 1;
    flex-basis: 90px;

    .ql-editor {
      height: auto;
    }
  }
}

.quill.no-toolbar .ql-editor {
  padding: 0.3rem 0.7rem;

  &:before {
    left: 0.9rem;
  }
}

.ql-editor ul > li::before {
  font-size: 24px;
  line-height: 1;
  width: 1.2em;
  vertical-align: middle;
}

.ql-editor ol,
.ql-editor ul {
  padding-left: 0;
}

.ql-editor img {
  border: 1px solid #d4d4d4;
}

.ql-tooltip {
  z-index: 1;
}

.ql-editor .mention {
  color: rgb(37 99 235);
}

.quill .ql-toolbar.ql-snow {
  border-top-left-radius: 0.2rem;
  border-top-right-radius: 0.2rem;
  border-color: #0044CC33;
}

.quill .ql-container.ql-snow {
  border-radius: 0.2rem;
  border: 1px solid #0044CC33;
  overflow: initial;

  img {
    cursor: zoom-in;
  }
}

.quill > .ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-radius: 0 0 0.2rem 0.2rem;
}

@media (max-width: 576px) {
  .quill .ql-toolbar {
    display: none;
  }

  .quill > .ql-toolbar.ql-snow + .ql-container.ql-snow {
    border-top-left-radius: 0.2rem;
    border-top-right-radius: 0.2rem;
    border-top: 1px solid #0044CC33;
  }
}

.quill.no-toolbar .ql-toolbar {
  display: none;
}

.quill.no-toolbar > .ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-top-left-radius: 0.2rem;
  border-top-right-radius: 0.2rem;
  border-top: 1px solid #0044CC33;
}

.quill.no-container .ql-container {
  border: none;
}

.quill.comment-styles .ql-container {
  font-family: inherit;
}

.quill.comment-styles .ql-editor {
  font-size: 14px;
  padding: 0;
}

.quill.text-sm .ql-editor {
  font-size: 14px;
}

.quill.resume {
  .ql-editor {
    display: -webkit-box;
    overflow: hidden;
    max-height: 38px;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;

    img {
      display: none;
    }

    @supports (line-clamp: 2) {
      line-clamp: 2;
    }
  }

  &.resume-inbox .ql-editor {
    max-height: 57px;
    -webkit-line-clamp: 3;

    @supports (line-clamp: 3) {
      line-clamp: 3;
    }
  }

  &.resume-one-line .ql-editor {
    max-height: 19px;
    -webkit-line-clamp: 1;

    @supports (line-clamp: 1) {
      line-clamp: 1;
    }
  }

  .ql-container .ql-editor {
    @supports (-webkit-line-clamp: 1) or (line-clamp: 1) {
      max-height: none;
    }
  }
}

.ql-container .ql-mention-list-container {
  z-index: 1000;
  background-color: white;
  border-radius: 0.25rem;
  border: 1px solid #e5e7eb;
  overflow: auto;

  .ql-mention-list {
    list-style: none;
    margin: 0;
    padding: 0.5rem 0;
    max-height: 250px;
    min-width: 220px;
    max-width: 350px;
  }

  .ql-mention-list-item {
    cursor: pointer;
    padding: 0.3rem 1rem;
  }

  .ql-mention-list-item[data-is-title] {
    cursor: initial;
    padding: 0 0.6rem;
  }

  .ql-mention-list-item.selected {
    background-color: rgb(37 99 235);
    color: white;
  }
}

.mention-compact .ql-container .ql-mention-list-container .ql-mention-list {
  max-height: 150px;
}

.ql-editor.ql-blank::before {
  font-style: normal;
  color: #bfbfbf;
}

.quill.deleted-message {
  font-style: italic;
  opacity: 0.3;
}

.yarl__container, .yarl__thumbnails_container {
  background-color: rgba(0, 0, 0, .8);
}

.yarl__thumbnails_vignette {
  display: none;
}

.quill.quill-has-more-button {
  &:not(.quill-container-h-full) {
    .ql-container {
      min-height: 90px;
    }

    .ql-editor {
      height: auto;
    }

    &.quill-reduced-size .ql-container {
      height: 90px;
    }
  }

  &.h-middle {
    .ql-container {
      min-height: 145px;
    }

    &.quill-reduced-size .ql-container {
      height: 145px;
    }
  }

  .ql-container .ql-editor {
    padding-bottom: 47px;
    min-height: unset;
  }

  &.quill-reduced-size .ql-container {
    overflow: hidden;

    .ql-cursors {
      display: none;
    }
  }
}

.quill-content-show {
  position: absolute;
  padding-bottom: 6px;
  background: transparent;
  width: auto;
  bottom: 1px;
  left: 50%;
  transform: translateX(-50%);

  &.quill-content-show--more {
    box-shadow: 0 -10px 25px 10px rgba(255, 255, 255, .85);
    background: #FFF;
    width: calc(100% - 18px);
    left: 8px;
    transform: none;
  }

  button {
    display: block;
    font-weight: bold;
    margin: 0 auto;
  }
}

.quill-image-progress-bar {
  display: block;
  line-height: 0;
  position: absolute;

  > .ant-progress-outer {
    padding: 0;

    .ant-progress-bg {
      height: 3px !important;
    }
  }
}

.quill {
  .ql-editor p, .ql-editor ol, .ql-editor ul, .ql-editor pre, .ql-editor blockquote, .ql-editor h1, .ql-editor h2, .ql-editor h3, .ql-editor h4, .ql-editor h5, .ql-editor h6 {
    margin-bottom: .5rem;
  }
}

@primary-color: #0044CC;@font-family: 'Roboto', sans-serif;